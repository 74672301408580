const ServicesAdditional = ({ heading, services }) => (
  <section className="bg-near-white pv5">
    <div className="container sm:pv3 md:pv4 lg:pv5">
      <h2 className="section-heading">
        {heading.black}
        <span className="db yellow">{heading.yellow}</span>
      </h2>

      <ul className="nt4 sm:nt3">
        {services.map(service => (
          <Service key={service.node.heading} service={service.node} />
        ))}
      </ul>
    </div>
  </section>
)

const Service = ({ service }) => (
  <li className="additional-grid mt5 md:pt3 lg:pt4">
    <Img
      fluid={service.image.file.childImageSharp.fluid}
      alt={service.image.alt}
      objPosition={service.image.objPosition}
      className="thumbnail b--white mb4"
    />

    <div>
      <h3 className="section-subheading mb3 sm:pb2">{service.heading}</h3>

      <p className="measure lh-copy">{service.paragraph}</p>

      <ScrollTo href="#contact" className="link heading mt4 f4 yellow">
        Call Today
      </ScrollTo>
    </div>
  </li>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

import Img from '../components/Img'
import ScrollTo from '../components/ScrollTo'

export default ServicesAdditional
