const ServicesPage = ({ data }) => {
  const hero = data.servicesYaml.hero
  const main = data.servicesYaml.main
  const additional = data.servicesYaml.additional
  const services = data.allServiceListYaml.edges
  const contact = data.contactYaml

  // Separate main services from additional services
  const mainServices = services.filter(service => service.node.category === 'main')
  const additionalServices = services.filter(
    service => service.node.category === 'additional'
  )

  // Remove Asphalt from the list of services shown on the Contact form
  const contactServiceList = services.filter(
    service => service.node.heading !== 'Asphalt Maintenance'
  )

  return (
    <Base>
      <PageMetadata page={data.site.siteMetadata.servicesPage} />

      <main id="main-content" tabIndex="-1" className="mukta dark-gray">
        <Hero
          title={hero.title}
          tagline={hero.tagline}
          link={hero.link}
          image={hero.image}
        />

        <Main
          heading={main.heading}
          paragraph={main.paragraph}
          services={mainServices}
        />

        <Additional heading={additional.heading} services={additionalServices} />

        <Contact
          stamp={contact.stamp}
          heading={contact.heading}
          invitation={contact.invitation}
          services={contactServiceList}
          successMessage={contact.successMessage}
        />
      </main>
    </Base>
  )
}

/*
 *
 * Queries
 * 
 */

export const query = graphql`
  query {
    site {
      siteMetadata {
        servicesPage {
          description
          title
          url
        }
      }
    }
    servicesYaml {
      hero {
        title {
          white
          yellow
        }
        tagline
        link {
          href
          text
        }
        image {
          file {
            childImageSharp {
              fluid(maxWidth: 6000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          objPosition
        }
      }
      main {
        heading {
          black
          yellow
        }
        paragraph
      }
      additional {
        heading {
          black
          yellow
        }
      }
    }
    allServiceListYaml {
      edges {
        node {
          heading
          category
          paragraph
          image {
            file {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            objPosition
          }
          gallery {
            image {
              childImageSharp {
                thumbnail: fluid(maxWidth: 730) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                lightbox: fixed(width: 1500) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            alt
            objPosition
            caption
          }
        }
      }
    }
    contactYaml {
      stamp {
        largeText
        smallText
      }
      heading
      invitation
      successMessage
    }
  }
`

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { graphql } from 'gatsby'

import Base from '../components/Base'
import PageMetadata from '../components/PageMetadata'
import Hero from '../components/Hero'
import Main from '../sections/ServicesMain'
import Additional from '../sections/ServicesAdditional'
import Contact from '../sections/Contact'

export default ServicesPage
