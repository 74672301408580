const ServicesMain = ({ heading, paragraph, services }) => (
  <section className="container pv5 sm:mv3 md:mv4 lg:mv5">
    <h2 className="section-heading mb4 sm:pb3">
      {heading.black}
      <span className="db yellow">{heading.yellow}</span>
    </h2>

    <p className="measure-wide lh-copy">{paragraph}</p>

    <ul className="nt4 sm:nt3">
      {services.map(service => (
        <Service key={service.node.heading} service={service.node} />
      ))}
    </ul>
  </section>
)

const Service = ({ service }) => (
  <li className="main-grid mt5 md:pt3 lg:pt4">
    <Img
      fluid={service.image.file.childImageSharp.fluid}
      alt={service.image.alt}
      objPosition={service.image.objPosition}
      className="thumbnail b--near-white"
    />

    <div>
      <h3 className="section-subheading mt4 mb3 sm:pb2">{service.heading}</h3>

      <p
        className="measure-wide lh-copy"
        dangerouslySetInnerHTML={{ __html: service.paragraph }}
      />

      <Collapse
        renderContent={() => <Gallery images={service.gallery} />}
        renderToggle={(expanded, handleToggle) =>
          !expanded && <ViewGallery handleToggle={handleToggle} />
        }
      />
    </div>
  </li>
)

/*
 *
 * View Gallery
 *
 */

const ViewGallery = ({ handleToggle }) => (
  <button onClick={handleToggle} className="link heading yellow mt4 f4">
    View Gallery
  </button>
)

/*
 *
 * Gallery
 *
 */

const Gallery = ({ images }) => {
  let limit = 3

  // Wait for the document to exist
  if (typeof window !== `undefined`) {
    // Create a test element to check for grid support
    const testEl = document.createElement(`div`)
    testEl.style.display = `grid`

    // If grid is supported, show more items initially on large screens
    if (testEl.style.display === `grid`) {
      if (window.matchMedia(`(min-width: 75em)`).matches) {
        limit = 6
      } else if (window.matchMedia(`(min-width: 48em)`).matches) {
        limit = 4
      }
    }
  }

  return (
    <Limit items={images} limit={limit} increment={limit}>
      {(visibleItems, limited, handleLoadMore) => (
        <>
          <ImageGallery items={images} visibleItems={visibleItems} />
          {limited && <ViewMore handleLoadMore={handleLoadMore} />}
        </>
      )}
    </Limit>
  )
}

/*
 *
 * Image Gallery
 *
 */

const ImageGallery = ({ items, visibleItems }) => (
  <ImageGalleryAndLightbox
    galleryImages={visibleItems}
    lightboxImages={items}
    renderGallery={(galleryImages, handleImageClick) => (
      <Thumbnails items={galleryImages} handleImageClick={handleImageClick} />
    )}
  />
)

/*
 *
 * Items
 *
 */

const Thumbnails = ({ items, handleImageClick }) => (
  <ul className="media-grid pt3">
    <TransitionGroup component={null}>
      {items.map((item, i) => (
        <Mount
          key={item.image.childImageSharp.thumbnail.src} // MUST be unique
          animateSpace={false}
          animateExit={false}
          enterTimeout={700}
          exitTimeout={0}
        >
          <Thumbnail
            item={item}
            lightboxIndex={i}
            handleImageClick={handleImageClick}
          />
        </Mount>
      ))}
    </TransitionGroup>
  </ul>
)

/*
 *
 * Thumbnail
 *
 */

const Thumbnail = ({ item, lightboxIndex, handleImageClick, className = `` }) => (
  <li className={`group relative mt3 ${className}`}>
    <Img
      fluid={item.image.childImageSharp.thumbnail}
      alt={item.alt}
      objPosition={item.objPosition}
      className="aspect-ratio aspect-ratio--8x5"
    />

    {/* Overlay + Lightbox trigger */}
    <button
      onClick={handleImageClick}
      value={lightboxIndex}
      className="flex justify-center items-center absolute fill bg-transparent w-100 group-hover:bg-black-50 f4 ttu transparent group-hover:white animate"
    >
      View image
    </button>
  </li>
)

/*
 *
 * See More Button
 *
 */

const ViewMore = ({ handleLoadMore }) => (
  <button onClick={handleLoadMore} className="link heading mt4 pt1 f4 yellow">
    View more
  </button>
)

/*
 *
 * Import & Exports
 *
 */

import React from 'react'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import Collapse from '../components/Collapse'
import ImageGalleryAndLightbox from '../components/ImageGalleryAndLightbox'
import Img from '../components/Img'
import Limit from '../components/Limit'
import Mount from '../components/Mount'

export default ServicesMain
